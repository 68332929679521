<div class="button-group">
    <slot />
</div>

<style>
    .button-group {
        display: flex;
        flex-direction: row;
        border-radius: 3px;
        overflow: hidden;
        margin: 20px 10px 20px 0;
        box-shadow: 0px 0px 5px 0px rgba(140, 140, 136, 1);
        width: max-content;
    }
</style>
